<template>
  <!-- Make a div wrapped slider,set height and width -->
  <!-- height:400px -->
  <div style="width:100%;margin:20px auto;">
  <slider ref="slider" :options="options" >
    <!-- slideritem wrapped package with the components you need someList -->
    <slideritem v-for="(item,index) in someList" :key="index" :style="item.style">
      <img class="spwier_class" style="width: 100%;" :src="item.img" alt="">
    </slideritem>
    <!-- Customizable loading -->
    <div slot="loading">loading...</div>
  </slider>
  <div class="swpier_button">
    <div @click="slideLeft" class="carousel-arrow left"></div>
    <div @click='slideRight' class="carousel-arrow right"></div>
  </div>

  <div class='swipers_lis'>
<!-- <Swipers></Swipers> -->
</div>
</div>

  </template>

  <script>
  // import slider components
  import { slider, slideritem } from 'vue-concise-slider'
// import Swipers from '@/views/Swipers.vue'
  export default {
    components: {
    // Swipers,
  },
  props:{
    dataImg: {
      default: "default value"
    },
  },
  data () {
     return {
      currentPage: 0,
       someList:[
       ],
       //Slider configuration [obj]
       options: {
         currentPage: 0,
         tracking: false,
         thresholdDistance: 100,
         thresholdTime: 500,
         infinite: 4,
         slidesToScroll: 1,
         disableMouseScroll: true, // 添加这个属性来禁止使用鼠标滚动
        //  loop: true
       }
     }
   },
   components: {
     slider,
     slideritem
   },
   mounted(){
      this.someList=this.dataImg
   },
   methods: {
      slideLeft() {
         this.$refs.slider.$emit('slidePre')
      },
      slideRight() {
        this.$refs.slider.$emit('slideNext')
      },
},

  }
  </script>

<style lang="scss" >
.slider-item{
  height: 400px;
  transition: all 3s ease;  
}
.slider-touch{
  // transition: duration 1500ms!important;
}
.slider-pagination{
  display:none !important;
}
.carousel-arrow {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #000;
      position: relative;
      cursor: pointer;
    }

    .carousel-arrow:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 7px;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      transition: transform 0.3s ease-in-out;
    }

    .carousel-arrow.left:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .carousel-arrow.right:before {
      transform: translate(-50%, -50%) rotate(222deg);
    }
    .swpier_button{
      display:flex;
      align-items: center;
    justify-content: center;
    margin-top: 10px;
      >div{
        margin-left: 30px;

      }
    }
    .founder_park .park_swiper{
      bottom: 0px !important;
    }
</style>