<template>
  <div class="warp_box">
      <div class="geekpark_warp_box">
          <div class="geekpark_top_img">
            <div class="geekpark_top_img_box">  
                  <div class="geek_top_left">GEEK PARK</div> 
                  <div class="geek_top_right">
                    <HomeView :InputName="parentInputName1" :keyValue="parentKey1" />
                    <HomeView :InputName="parentInputName2" :keyValue="parentKey2"  />
                    <HomeView :InputName="parentInputName3" :keyValue="parentKey3" />
                    <HomeView :InputName="parentInputName4" :keyValue="parentKey4" />
                  </div> 
            </div>
              <div class="geekpark_logo">
                <!-- 极客公园 -->
                <!-- <div class="mask">
                    <p> 极客公园</p>
              </div> -->
                    
               </div>
          </div>
      </div>
      <div class="geekpark_latest_box">
        <div class="geekpark_latest">
           LATEST NEWS
        </div>
        <div class='geekpark_latest_right'>
          
        </div>
      </div>
      <div class="latest_title">  
          <div class="title_left">
            <span>（TRENDING）</span>
              <p>正在发生</p>
          </div>
          <div class="title_right">
             <span span>（TRENDING）</span>
              <p>编辑精选</p>
          </div>

      </div>
      <div class="latest_news">
          <div class="latest_news_item">
            <div class="news_item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_Y6mbPz3a0VRhzK9f0-xR7hUAULduVppnC5jLKsmWuQ&s" alt="">
                  <p>语言背后的全球化创业思维</p>
                  <span>12.26 19:00 - 20:00</span>
                  <button style="color: #fff; background: black;">THE FRONTER</button>
              </div>
              <div class="news_item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_Y6mbPz3a0VRhzK9f0-xR7hUAULduVppnC5jLKsmWuQ&s" alt="">
                  <p>语言背后的全球化创业思维</p>
                  <span>12.26 19:00 - 20:00</span>
                  <button style="background: #054BFF; color:white;">GLOBAL READY</button>
              </div>
              <div class="news_item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_Y6mbPz3a0VRhzK9f0-xR7hUAULduVppnC5jLKsmWuQ&s" alt="">
                  <p>语言背后的全球化创业思维</p>
                  <span>12.26 19:00 - 20:00</span>
                  <button style=" background:  var(--AGI, #CEFF00);">AGI PLAYGROUND</button>

              </div>
              <div class="news_item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_Y6mbPz3a0VRhzK9f0-xR7hUAULduVppnC5jLKsmWuQ&s" alt="">
                  <p>语言背后的全球化创业思维</p>
                  <span>12.26 19:00 - 20:00</span>
                  <button style="background: var(--IF, #FF003C);">GLOBAL READY</button>
              </div>
              <div class="news_item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_Y6mbPz3a0VRhzK9f0-xR7hUAULduVppnC5jLKsmWuQ&s" alt="">
                  <p>语言背后的全球化创业思维</p>
                  <span>12.26 19:00 - 20:00</span>
                  <button style="background: var(--HACKATHON-BG, #7D27FF);">GLOBAL READY</button>
              </div>
              <div class="news_item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_Y6mbPz3a0VRhzK9f0-xR7hUAULduVppnC5jLKsmWuQ&s" alt="">
                  <p>语言背后的全球化创业思维</p>
                  <span>12.26 19:00 - 20:00</span>
                  <button style="background: var(--WORKSHOP, #FF303E);">GLOBAL READY</button>
              </div>
              <div class="news_item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_Y6mbPz3a0VRhzK9f0-xR7hUAULduVppnC5jLKsmWuQ&s" alt="">
                  <p>语言背后的全球化创业思维</p>
                  <span>12.26 19:00 - 20:00</span>
                  <button style='background: var(--DEMOSHOW, #5DEE00);'>GLOBAL READY</button>
              </div>

              <div class="news_item">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_Y6mbPz3a0VRhzK9f0-xR7hUAULduVppnC5jLKsmWuQ&s" alt="">
                  <p>语言背后的全球化创业思维</p>
                  <span>12.26 19:00 - 20:00</span>
                  <button>GLOBAL READY</button>
              </div>
          </div>
          <div class="choice">
            <div class="choice_list">
                  <p >销售销售人员小米汽车多地招聘销售销售人员小米汽车多地招聘销售销售人员小米汽车多地招聘销售</p>
                  <p style="color: black;">小米汽车多地招聘销售人员；字节跳动 2023 年销售额或超...</p>
                  <p>小米汽车多地招聘销售人员；字节跳动 2023 年销售额或超...</p>
                  <p>小米汽车多地招聘销售人员；</p>
                  <p>小米汽车多地招聘销售人员；字节跳动 2023 年销售额或超...</p>
                  <p>小米汽车多地招聘销售人员；字节跳动 2023 年销售额或超...</p>
            </div>
      </div>
      </div>
      <el-divider></el-divider>
      <AboutView></AboutView>
      <div class="geektech">
            <p>GEEKPARK</p>
            <div class='geektech_table'>
              <P>TECH</P>
              <div class="geektech_table_item">
                <span>
                  <span>(01)</span>
                  <span>GEEK INSIGHT</span>
                </span>
                <span>
                  <span>(02)</span>
                  <span>SCRIPT</span>
                </span>
                <span>
                  <span>(03)</span>
                  <span>NEW SPOT</span>
                </span>
                <span>
                  <span>(04)</span>
                  <span>TECH WITH HAART</span>
                </span>
                <span>
                  <span>(05)</span>
                  <span>GEEK WHEELS</span>
                </span>
              </div>
            </div>
            <p>MEDIA</p>

      </div>
      <div class="geek_medis">
          <div class='geek_medis_item'>
                <div>
                  <img src="./assets/1.png" alt="">
                  <h5>特稿科技</h5>
                </div>
                <div>
                  <p>过去八个月，百川智能团队依托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 </p>
                  <span>
               
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                      <path d="M0.240234 0.340088H25.1802" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="square"/>
                      <path d="M0.240234 0.340088L25.7202 25.8201" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10"/>
                      <path d="M0.240234 0.340088V25.28" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="square"/>
                      </svg>
                    
                  </span>
                 </div>
          </div>
          <div class='geek_medis_item'>
                <div>
                  <img src="./assets/1.png" alt="">
                  <h5>特稿科技</h5>
                </div>
                <div>
                  <p>过去八个月，百川智能团队依托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 </p>
                  <span>
               

                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                      <path d="M0.240234 0.340088H25.1802" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="square"/>
                      <path d="M0.240234 0.340088L25.7202 25.8201" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10"/>
                      <path d="M0.240234 0.340088V25.28" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="square"/>
                      </svg>
                  </span>
                 </div>
          </div>
          <div class='geek_medis_item'>
                <div>
                  <img src="./assets/1.png" alt="">
                  <h5>特稿科技</h5>
                </div>
                <div>
                  <p>过去八个月，百川智能团队依托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 </p>
                  <span>
               

                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                        <path d="M0.240234 0.340088H25.1802" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="square"/>
                        <path d="M0.240234 0.340088L25.7202 25.8201" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10"/>
                        <path d="M0.240234 0.340088V25.28" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="square"/>
                        </svg>
                  </span>
                 </div>
          </div>
          <div class='geek_medis_item'>
                <div>
                  <img src="./assets/1.png" alt="">
                  <h5>特稿科技</h5>
                </div>
                <div>
                  <p>过去八个月，百川智能团队依托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 </p>
                  <span>
               
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                        <path d="M0.240234 0.340088H25.1802" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="square"/>
                        <path d="M0.240234 0.340088L25.7202 25.8201" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10"/>
                        <path d="M0.240234 0.340088V25.28" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="square"/>
                        </svg>
                    
                  </span>
                 </div>
          </div>
          <div class='geek_medis_item'>
                <div>
                  <img src="./assets/1.png" alt="">
                  <h5>特稿科技</h5>
                </div>
                <div>
                  <p>过去八个月，百川智能团队依托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 托对搜索、高质量数据处理等 </p>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                      <path d="M0.240234 0.340088H25.1802" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="square"/>
                      <path d="M0.240234 0.340088L25.7202 25.8201" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10"/>
                      <path d="M0.240234 0.340088V25.28" stroke="#666666" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="square"/>
                      </svg>
                  </span>
                 </div>
          </div>
    
      </div>

      <div class="founder_park">
            <p>
              FOUNDERPARK
            </p>
            <div class="park_swiper">
              <SwiperView v-show="isShowCompany" class="animate__animated animate__fadeInRight" :dataImg="someList"></SwiperView>
            </div>
            <div class="geektech_table_item">
                <span>
                  <span>(01)</span>
                  <span>AGI PLAYGROUND</span>
                </span>
                <span>
                  <span>(02)</span>
                  <span>GLOBAL READY</span>
                </span>
                <span>
                  <span>(03)</span>
                  <span>THE FRONTER</span>
                </span>
                <span>
                  <span>(04)</span>
                  <span>VARCAPITAL</span>
                </span>
              </div>
      </div>

      <div class="founder_park_two">
            <p>
              COMMUNTYEVENTS
            </p>
            <div class="park_swiper">
              <!-- animate__fadeInRight -->
              <SwiperView v-show="isShow" class="animate__animated " :dataImg="someList_two"></SwiperView>
           
            </div>
            <div class="geektech_table_item">
                <span>
                  <span>(01)</span>
                  <span>GEEKPARK INNOFEST</span>
                </span>
                <span>
                  <span>(02)</span>
                  <span>AGI PLAYGROUND</span>
                </span>
                <span>
                  <span>(03)</span>
                  <span>HACKATHON</span>
                </span>
                <span>
                  <span>(04)</span>
                  <span>WORKSHOP</span>
                </span>
                <span>
                  <span>(05)</span>
                  <span>DEMOSHOW</span>
                </span>
              </div>
      </div>

      <div class="geekpark_footer">
            <div class="footer_title">
                CONNECT WITH US
            </div>
            <div class="latest_titles">  
              <div class="title_left">
                <span>（CONTACT US）</span>
                  <p>联系我们</p>
              </div>
              <div class="title_right">
                <span span>（CONTACT US）</span>
                  <p>加入我们</p>
              </div>
          </div> 
          <div class="footer_bd">
                <div class="footer_bd_item">
                  <div class='bd_left'>
                  <span>(商务合作)</span>
                </div>
                <div class="bd_right" style="
                      border-bottom: 1px solid #231815;
                      padding-bottom: 28px;
                      padding-right: 121px;
                  ">

                <a href="mailto:bd@geekpark.net">
                  <p style='font-size: 20px;'>bd@geekpark.net</p>
                </a>
                  <span>
                    或至微信公众号「极客公园不是公园」后台留言
                  </span>
                </div>
              </div>
            
              <div class="footer_bd_item">
                  <div class='bd_left'>
                  <span>(商务合作)</span>
                </div>
                <div class="bd_right">
                  <a href="mailto:bd@geekpark.net">
                  <p style='font-size: 20px;'>bd@geekpark.net</p>
                </a>
                  <span>
                    或至微信公众号「极客公园不是公园」后台留言
                  </span>
                </div>
              </div>
            </div>   
            
            <div class="latest_titles">  
              <div class="title_left">
                <span style='margin-left: 20px;'>（媒体联系）</span>
                <a href="mailto:if.media@geekpark.net">
                  <p style='font-size: 20px;'>if.media@geekpark.net</p>
                </a>
              </div>
          </div> 
     </div>

     <div class="footer_img">
      <img src="./assets/Jfvef1zOvq.jpg" alt="">
     </div>
  </div>
</template>
<script>
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import SwiperView from '@/views/SwiperView.vue'
export default {
  name: "YourComponentName",
  components: {
    HomeView,
    AboutView,
    SwiperView,
  },
  mounted(){
    window.addEventListener('scroll',this.handleScroll,true)
 
 },
destroyed () {
    window.removeEventListener('scroll', this.handleScroll, false)
  },
 data(){
    return{
      someList:[
         {
           html: 'slider1',
           img:'http://lc-RanMoNzS.cn-n1.lcfile.com/qzedmUGQ64AVpP8v78Ia8rJqtTE9nprH/%E6%9E%81%E5%AE%A2%E5%85%AC%E5%9B%ADLP-02-01.png',
           style: {
             'width': '49%',
             'margin-right': '2%'
           }
         },
         {
           html: 'slider2',
           img:'http://lc-RanMoNzS.cn-n1.lcfile.com/SpUHj5e63Q8mRhHHXWIrd511hw8staDI/%E6%9E%81%E5%AE%A2%E5%85%AC%E5%9B%ADLP-02-02.png',
           style: {
              'width': '49%',
             'margin-right': '2%'
           }
         },
         {
           html: 'slider3',
           img:'http://lc-RanMoNzS.cn-n1.lcfile.com/Q7hpcgDlMgv8r2JPGopjX8h9zcdNC5HD/%E6%9E%81%E5%AE%A2%E5%85%AC%E5%9B%ADLP-02-03.png',
           style: {
           
              'width': '49%',
             'margin-right': '2%'
           }
         },
         {
           html: 'slider4',
           img:'http://lc-RanMoNzS.cn-n1.lcfile.com/N75nSKexxb4dtIrdAPDBmDP0ncTzINry/%E6%9E%81%E5%AE%A2%E5%85%AC%E5%9B%ADLP-02-04.png',
           style: {
            
              'width': '49%',
             'margin-right': '2%'
           }
         },
         {
           html: 'slider5',
           img:'http://lc-RanMoNzS.cn-n1.lcfile.com/9cr9O2VTnJ2fceOjJfe3m4M7Lr0bh2EM/%E6%9E%81%E5%AE%A2%E5%85%AC%E5%9B%ADLP-02-05.png',
           style: {
              'width': '49%',
             'margin-right': '2%'
           }
         },
       ],
       someList_two:[
       {
           html: 'slider1',
           img:'http://lc-RanMoNzS.cn-n1.lcfile.com/ad7LSgkddX7rWb6wNdnfSicljCHgcHiN/%E5%B0%8Fbanner-07.png',
           style: {
             'width': '49%',
             'margin-right': '2%'
           }
         },
         {
           html: 'slider2',
           img:'http://lc-RanMoNzS.cn-n1.lcfile.com/VtzxgilF1DmDyp4rcjH48p0Tk6TnT0We/%E5%B0%8Fbanner-08.png',
           style: {
              'width': '49%',
             'margin-right': '2%'
           }
         },
         {
           html: 'slider3',
           img:'http://lc-RanMoNzS.cn-n1.lcfile.com/Tjj9ra6p47LVKSA6gUEDDhXKLrjNHaQK/%E5%B0%8Fbanner-09.png',
           style: {
           
              'width': '49%',
             'margin-right': '2%'
           }
         },
         {
           html: 'slider4',
           img:'http://lc-RanMoNzS.cn-n1.lcfile.com/XgsEdhxbBWjb4hNYAsvMUGN6OfAHfabk/%E5%B0%8Fbanner-10.png',
           style: {
            
              'width': '49%',
             'margin-right': '2%'
           }
         },
         {
           html: 'slider5',
           img:'http://lc-RanMoNzS.cn-n1.lcfile.com/LXGDRyV0zWygbORmcPNPCzYPIH0ymQM5/%E5%B0%8Fbanner-11.png',
           style: {
              'width': '49%',
             'margin-right': '2%'
           }
         },
       ],
       isShow:false,
       isShowCompany:false,
      parentKey1:'(01)',
      parentKey2:'(02)',
      parentKey3:'(03)',
      parentKey4:'(04)',
      parentKey5:'(05)',
      parentInputName1: [
        {
          firstLetters:['科','技','资','讯'],
          secondLetters:['科','技','资','讯'],
        },
      ],
      parentInputName2: [
        {
          firstLetters:['创','始','人','社','区'],
          secondLetters:['创','始','人','社','区'],
          indexKey:2
        },
      ],
      parentInputName3: [
        {
          firstLetters:['社','区','活','动'],
          secondLetters:['社','区','活','动'],
          indexKey:3
        },
      ],
      parentInputName4: [
        {
          firstLetters:['联','系','我','们'],
          secondLetters:['联','系','我','们'],
          indexKey:4
        },
      ],
    }
 },
 methods:{
  handleScroll() {
    const el = document.querySelector('.founder_park');
    const elOffsetTop = el.offsetTop;
    // const docScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY || window.pageYOffset;
    if (scrollY + windowHeight >= elOffsetTop + 800 && !this.isShowCompany) {
      // 当前元素在屏幕可视范围内
      // 执行动画
      console.log('elOffsetTop',elOffsetTop);
      console.log('执行动画');
      this.isShowCompany = true;
      var swpier_buttons=document.querySelectorAll('.park_swiper .swpier_button')
      console.log(swpier_buttons,'swpier_buttons');
      setTimeout(() => {
        swpier_buttons[0].style.display='flex'

        var slider_items=document.querySelectorAll('.slider-item')
        // var spwier_class=document.querySelectorAll('.spwier_class')

        console.log(slider_items,'slider_items>img');
        // for (let i = 1; i <= 5; i++) {


          var seenterimg=[
            'http://lc-RanMoNzS.cn-n1.lcfile.com/qzedmUGQ64AVpP8v78Ia8rJqtTE9nprH/%E6%9E%81%E5%AE%A2%E5%85%AC%E5%9B%ADLP-02-01.png',
            'http://lc-RanMoNzS.cn-n1.lcfile.com/SpUHj5e63Q8mRhHHXWIrd511hw8staDI/%E6%9E%81%E5%AE%A2%E5%85%AC%E5%9B%ADLP-02-02.png',
            'http://lc-RanMoNzS.cn-n1.lcfile.com/Q7hpcgDlMgv8r2JPGopjX8h9zcdNC5HD/%E6%9E%81%E5%AE%A2%E5%85%AC%E5%9B%ADLP-02-03.png',
            'http://lc-RanMoNzS.cn-n1.lcfile.com/N75nSKexxb4dtIrdAPDBmDP0ncTzINry/%E6%9E%81%E5%AE%A2%E5%85%AC%E5%9B%ADLP-02-04.png',
            'http://lc-RanMoNzS.cn-n1.lcfile.com/9cr9O2VTnJ2fceOjJfe3m4M7Lr0bh2EM/%E6%9E%81%E5%AE%A2%E5%85%AC%E5%9B%ADLP-02-05.png'
          ]

          var mouseenimg=[
            'http://lc-RanMoNzS.cn-n1.lcfile.com/Er4nNWJ0ayEVW9L8lYruoWt6sguFjz1F/%E5%B0%8Fbanner-02.png',
            'http://lc-RanMoNzS.cn-n1.lcfile.com/zsUAqqDv1ryRzuJV5vm04NdkSxwnKrqH/%E5%B0%8Fbanner-03.png',
            'http://lc-RanMoNzS.cn-n1.lcfile.com/uzTKubhrmqAXhH8pJAScfVYjow9MS12i/%E5%B0%8Fbanner-04.png',
            'http://lc-RanMoNzS.cn-n1.lcfile.com/qpktyEuNbFyeJmNbYrYzeRpojdxJB6i0/%E5%B0%8Fbanner-05.png',
            'http://lc-RanMoNzS.cn-n1.lcfile.com/KOX9oSckU3qBnM8aAn0LwmFw9ah00q6g/%E5%B0%8Fbanner-06.png'
          ]

          // const mouseenterHandler = (e) => {
          //   console.log(e.target, 'sssssssssssss');
          //   slider_items[1].innerHTML = `<img src=\"http://lc-RanMoNzS.cn-n1.lcfile.com/Er4nNWJ0ayEVW9L8lYruoWt6sguFjz1F/%E5%B0%8Fbanner-02.png\" alt=\"\" style=\"width: 100%;\">`;
          //   slider_items[1].removeEventListener('mouseenter', mouseenterHandler);
          // };
          // slider_items[1].addEventListener('mouseenter', mouseenterHandler);
          // slider_items[1].addEventListener('mouseleave', (e) => {
          //   console.log(e.target, 'sssssssssssss');
          //   slider_items[1].innerHTML = `<img src=\"http://lc-RanMoNzS.cn-n1.lcfile.com/ad7LSgkddX7rWb6wNdnfSicljCHgcHiN/%E5%B0%8Fbanner-07.png\" alt=\"\" style=\"width: 100%;\">`;
          //   slider_items[1].addEventListener('mouseenter', mouseenterHandler);
          // });


            const mouseenterHandler = (e) => {
              const index = Array.from(slider_items).indexOf(e.target);
              slider_items[index].innerHTML = `<img class="animate__animated  " src="${mouseenimg[index]}" alt="" style="width: 100%;">`;
              slider_items[index].removeEventListener('mouseenter', mouseenterHandler);
            };
            // animate__fadeInDown animate__fadeInRight
            const mouseleaveHandler = (e) => {
              const index = Array.from(slider_items).indexOf(e.target);
              slider_items[index].innerHTML = `<img  class="animate__animated animate__fadeIn " src="${seenterimg[index]}" alt="" style="width: 100%;">`;
              slider_items[index].addEventListener('mouseenter', mouseenterHandler);
            };

            for (let i = 0; i < 5; i++) {
              slider_items[i].addEventListener('mouseenter', mouseenterHandler);
              slider_items[i].addEventListener('mouseleave', mouseleaveHandler);
            }

        // }
      }, 1500);
    }

    const els = document.querySelector('.founder_park_two');
    const elOffsetTops = els.offsetTop;
    // const docScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeights = window.innerHeight;
    const scrollYs = window.scrollY || window.pageYOffset;
    if (scrollYs + windowHeights >= elOffsetTops + 800 && !this.isShow) {
      // 当前元素在屏幕可视范围内
      // 执行动画
      console.log('elOffsetTop',elOffsetTops);
      console.log('执行动画');
      this.isShow = true;  
      var swpier_button=document.querySelectorAll('.swpier_button')
      console.log(swpier_button,'swpier_button');
      setTimeout(() => {
        swpier_button[1].style.display='flex'
      }, 1500);
    }
  },
  }
}
</script>
<style lang="scss">
body{
    cursor:url("https://static.fotor.com.cn/assets/projects/pages/183f4296-945d-41d5-ae5e-59381afde13f/fotor-aeb69942-8ea0-4140-a621-4425d2cae66c.jpg"),auto;
}
.animate__animated.animate__fadeInRight {
  --animate-duration: 1s;
}
.swpier_button{
  display: none;
}
.el-divider--horizontal{
  margin: 57px 0px !important;
  height: 0.5px !important;
}
.el-divider{
  background-color:#231815 !important;
}
*{
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  font-family: 'OPPOL';

}
a{
  text-decoration: none; color:inherit;
}
body{
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
  // padding-bottom: 10%;
}
.warp_box{
  width: 100%;
}
.geekpark_warp_box{
   background: url(http://lc-RanMoNzS.cn-n1.lcfile.com/eeqyYMpL2PRKHvS7fek5pTJpv5kC2S4h/%E5%A4%B4%E5%9B%BE1440.png) no-repeat center/cover;
  // background:url('../src/assets/banner-02.png') no-repeat;
   background-size:100% 100%;
   padding-top: 23px;
  // color: rgb(255, 0, 111);
  //  background-color: currentColor;
   padding-bottom: 25%;
.geekpark_top_img{
  width: 85%;
  // background: white;
  margin: 0 auto;
  .geekpark_top_img_box{
  border-top: 1px solid #231815;;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  .geek_top_left{
    display: flex;
    align-items: center;
   font-family: 'OPPOB';
   font-size: 20px;

  }
  .geek_top_right{
    display: flex;
    justify-content: space-between;
    width: 60%;
    // filter: invert(100%);

  }
}
.geekpark_logo{
    text-shadow:#000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
    font-size:90px;
    // color: #000;
    padding-top: 8%;
    // background: currentColor;
    color:#000;
    // background: black;
    // filter: invert(100%);
    // background: url(https://preview.qiantucdn.com/58pic/20220306/00658PICgRUf8faHwvcX5_PIC2018_PIC2018.jpg!w1024_new_small) no-repeat center/cover;
    background: linear-gradient(to right,#ab5252,#146187,#3b4a39) no-repeat right bottom;
    background-size: 0px 2px;
     transition:background-size 1300ms;
     width: fit-content
     

  }
  .geekpark_logo:hover{
    background-position-x: left;
     background-size: 100% 2px;
  }
  
}
}
.geekpark_latest_box{
  width: 85%;
  display:flex;
  margin:0 auto;
  margin-top: 35px;
  border-bottom: 1px solid #231815;
  justify-content: space-between;
    .geekpark_latest{
    color: #000;
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // border-bottom: 1px solid #231815;
    // margin: 0 auto;
    width: fit-content;
    // padding-right: 10%;
  }
  .geekpark_latest_right{
    //  border-bottom: 1px solid #231815;
     width: 30%;
  }
}
.latest_title{
  width: 85%;
  margin:0 auto;
  display: flex;
  justify-content: space-between;
  .title_left{
    display: flex;
    margin-top: 16px;
    span{
      display: block;
      color: #808080;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    vertical-align: top;
      line-height: normal;
      margin-top: 5px;
      
    }
    p{
    vertical-align: top;
      display: inline-block;
      font-family: 'OPPOB';
      font-size: 30px;
    }

  }
  .title_right{
    display: flex;
    margin-top: 16px;
    margin-right: 67px;

    span{
      display: block;
      color: #808080;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 5px;
      
    }
    p{
    vertical-align: top;
      display: inline-block;
      font-family: 'OPPOB';
      font-size: 30px;
    }
  }
}
.latest_news{
  width: 85%;
  display:flex;
  margin: 0 auto;
  justify-content: space-between;
  .latest_news_item{
    width: 100%;
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    .news_item{
      width: 19%;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      margin-top: 10px;
      padding: 2% 2% 1% 2%;
       border: 1px solid #000;
       transform: translateX(0);
        /* 移回也需要0.4s时间过渡 */
        transition: transform 0.4s linear 0s;
       img{
        // width: 20%;
       }
       p{
        color: #000;
        font-family: 'OPPOB';
        font-size: 18px;
        margin-top: 5px;
       }
       span{
        color: #666;
        font-size: 12px;
        font-family: 'OPPOR';
        margin-top: 5px;

       }
       button{
        margin-top: 25px;
        border-radius: 30px;
        width: fit-content;
        padding: 6px 12px;
        border: none;
        font-family: 'OPPOB';

        font-size: 12px;
       }
    }
    .news_item:hover {
    /* x=-10px代表左移10px */
    transform: translateY(-10px);
    /* 0.4s完成transform移动效果*/
    transition: transform 0.4s linear 0s;
}
  }
}
.choice{
  // width: 52%;
  width: 30%;
  margin-top: 35px;
  .choice_list{
    p{
      border-bottom:1px solid #ccc;
      padding-bottom: 15px;
      margin-top: 15px;
      font-family: 'OPPOB';
      font-size:14px;
      color: #666;
      width: 100%;
      transition: color 0.4s linear 0s;
      // overflow: hidden;      文本溢出
      // text-overflow: ellipsis;
      // display:-webkit-box; //作为弹性伸缩盒子模型显示。
      // -webkit-box-orient:vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
      // -webkit-line-clamp:2; //显示的行
    }
    p:hover{
      color:#000;
       transition: color 0.4s linear 0s;
    }
    p:last-child {
      border-bottom: none;
    }
  }
  // text-align: center;
}
.geektech{
  width: 96%;
  margin: 0 auto;
  background: #F2F2F2;
  border:0.5px solid #000000;
  margin-top: 60px;
  padding: 40px 0px  100px 0px;
  p{
    font-size: 219px;
      font-family: 'OPPOM';
      line-height: 1;
    }
  >p:nth-child(1){
    margin-left: 5%;
  }
  >p:last-child{
    text-align:end;
  }
  .geektech_table{
    display:flex;
    align-items: center;
    .geektech_table_item{
      width:30%;
      >span:last-child{
        border:none;
      }
      >span{
        padding: 3px 0px 0px 0px;
        margin-left: 100px;
        display: block;
        border-bottom: 0.5px solid #231815;
        width: 100%;
        
        >span:nth-child(1){
          margin-left: 30px;
          font-family: 'OPPOR';
          font-size: 8px;
          transform:scale(0.88);
          margin-bottom: 5px;
          display: inline-block;
        }
        >span:nth-child(2){
          margin-left: 100px;
          font-family: 'OPPOR';
          font-size: 16px;

        }
      }
    }
  }
}

.geek_medis{
  width: 85%;
  margin: 0 auto;
  display:flex;
  justify-content: space-between;
  margin-top: 40px;

  .geek_medis_item{
    transform: translateX(0);
   /* 移回也需要0.4s时间过渡 */
   transition: transform 0.4s linear 0s;
    margin-left: 10px;
    width: 18%;
    // background: red;
    >div:nth-child(1){
      
      border-bottom: 1px solid #ccc;
      padding-bottom: 5px;
      h5{
        font-size: 20px;
        font-family: 'OPPOR';
      }
    }
    >div:nth-child(2){
      border-bottom: 1px solid #ccc ;
      padding-bottom: 20px;
      padding-top: 25px;

      span{
        display: block;
        text-align: end;
        margin-top: 17px;
      }
    }
  }
  >:hover{
        transform: translateY(10px);
        /* 0.4s完成transform移动效果*/
        transition: transform 0.4s linear 0s;
        >div:nth-child(2){
          background: #F5F5F5;
          border-bottom: 1px solid #231815;
           transition: background 0.4s linear 0s;
           transition:  border-bottom 0.4s linear 0s;
           >span> svg path{
            transition: color 0.4s linear 0s;
            stroke: #231815;
           transition: color 0.4s linear 0s;
       }
        }
  }
}
.founder_park{
  width: 96%;
  margin: 0 auto;
  background: #F2F2F2;
  border:0.5px solid #000000;
  margin-top: 60px;
  padding: 40px 0px  100px 0px;
  position: relative;
  P{
    font-size: 216.95px;
      font-family: 'OPPOM';
      line-height: 1;
      display: flex;
      flex-wrap: wrap;
      word-break: break-all;
      text-indent: 19%;
      padding-bottom: 230px;
      word-wrap: break-word;
  }
  .park_swiper{
    position: absolute;
    bottom: 50px;
    width: 100%;
  }
  .geektech_table_item{
    width: 25%;
    position: absolute;
    top: 87px;
    right: 20%;
    background: #f2f2f2;
    padding: 0px 30px;
      >span:last-child{
        border:none;
      }
      >span{
        padding: 3px 0px 0px 0px;
        display: block;
        border-bottom: 0.5px solid #231815;
        width: 100%;
        
        >span:nth-child(1){
          margin-left: 30px;
          font-family: 'OPPOR';
          font-size: 8px;
          transform:scale(0.88);
          margin-bottom: 5px;
          display: inline-block;
        }
        >span:nth-child(2){
          font-family: 'OPPOR';
          font-size: 16px;

        }
      }
    }
} 

.founder_park_two{
  width: 96%;
  margin: 0 auto;
  background: #F2F2F2;
  border:0.5px solid #000000;
  margin-top: 60px;
  padding: 40px 0px  100px 0px;
  position: relative;
  P{
    font-size: 221.95px;
      font-family: 'OPPOM';
      line-height: 1;
      display: flex;
      flex-wrap: wrap;
      word-break: break-all;
      // text-indent: 19%;
      padding-bottom: 230px;
      word-wrap: break-word;
      text-align: end;
  }
  .park_swiper{
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .geektech_table_item{
       width: 25%;
    position: absolute;
    top: 87px;
    right: 20%;
    background: #f2f2f2;
    padding: 0px 30px;
      >span:last-child{
        border:none;
      }
      >span{
        padding: 3px 0px 0px 0px;
        display: block;
        border-bottom: 0.5px solid #231815;
        width: 100%;
        >span:nth-child(1){
          margin-left: 30px;
          font-family: 'OPPOR';
          font-size: 8px;
          transform:scale(0.88);
          margin-bottom: 5px;
          display: inline-block;
        }
        >span:nth-child(2){
          font-family: 'OPPOR';
          font-size: 16px;

        }
      }
    }
} 
.founder_park_two .geektech_table_item > span > span:nth-child(2){
  margin-left: 100px;
}
.founder_park .geektech_table_item > span > span:nth-child(2){
  margin-left: 100px;
}


.geekpark_footer{
  width: 85%;
  margin: 0 auto;
  .footer_title{
    font-size: 90px;
    font-family: "OPPOL";
    border-bottom: 1px solid #000;
    margin-top: 60px;


  }
  .latest_titles{
    width: 70%;
  display: flex;
  justify-content: space-between;
  .title_left{
    display: flex;
    margin-top: 16px;
    span{
      display: block;
      color: #808080;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    vertical-align: top;
      line-height: normal;
      margin-top: 5px;
    }
    p{
    vertical-align: top;
      display: inline-block;
      font-family: 'OPPOB';
      font-size: 30px;
    }

  }
  .title_right{
    display: flex;
    margin-top: 16px;
    margin-right: 67px;

    span{
      display: block;
      color: #808080;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 5px;
      
    }
    p{
    vertical-align: top;
      display: inline-block;
      font-family: 'OPPOB';
      font-size: 30px;
    }
  }
}
.footer_bd{
  margin-top:30px;
  width: 82%;
  display: flex;
  justify-content: space-between;
  .footer_bd_item{
    display:flex;
    .bd_left{
      margin-left:30px;
      >span{
        font-family: "OPPOL";
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #808080;
      }
    }
    .bd_right{
      display:flex;
      flex-direction:column;
      margin-left: 10px;
      >span{
        font-family: "OPPOR";
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
      }
      p{
        vertical-align: top;
        display: inline-block;
        font-family: 'OPPOB';
        font-size: 20px;
        color: #000;
      }

      
    }
    
   
  }

}
}

.footer_img{
  width: 100%;
  margin-top: 85px;
  img{
    width: 100%;
    display: block;
  }
}
</style>
