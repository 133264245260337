<template>
<div class="wrap">
  <div v-for="(item, index) in InputName" :key="index" class="nav_list">
      <div class="first">
        <span>{{ keyValue }}</span>
        <div class="item" v-for="(letter, letterIndex) in item.firstLetters" :key="letterIndex">{{ letter }}</div>
      </div>
      <div class="second">
        <span>{{ keyValue }}</span>
        <div class="item" v-for="(letter, letterIndex) in item.secondLetters" :key="letterIndex">{{ letter }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    InputName: {
      default: "default value"
    },
    keyValue: {
      default: "default value"
    },

  },
   data(){
    return{

    }
   },
   mounted(){
    console.log(this.InputName,'InputName');    
    // console.log(this.default,'InputName');    
    // console.log(this,'InputName');    
   var nav_list= document.querySelectorAll('.nav_list')
   var founder_park= document.querySelector('.founder_park')
   var founder_park_two= document.querySelector('.founder_park_two')
   var geektech= document.querySelector('.geektech')
   console.log(nav_list,'InputName');
   console.log(founder_park,'founder_park');
   console.log(founder_park_two,'founder_park_two');
   console.log(geektech,'geektech');
   nav_list.forEach((item, index) => {
    item.addEventListener('click', () => {
      if (index === 0) {
        geektech.scrollIntoView({
          behavior: 'smooth'
        });
      } else if (index === 1) {
        founder_park.scrollIntoView({
          behavior: 'smooth'
        });
      } else if (index === 2) {
        founder_park_two.scrollIntoView({
          behavior: 'smooth'
        });
      }
      else if (index === 3) {
        window.location.href = `mailto:bd@geekpark.net`;
      }
    });
    });
   },
   methods:{
   }
}

</script>

<style lang="scss" scoped>

.wrap, .first, .second {
            /* border: 1px solid #ccc; */
            width: 120px;
            height: 30px;
            position: relative;
            overflow: hidden;
        }
        .first, .second {
            position: absolute;
            top: 0;
            left: 0;
            height: 30px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .second .item {
            transform: translateY(150%);
        }
        .first .item, .second .item {
               font-size: 18px;
              transition: transform .5s;
              font-family:'OPPOR'
        }
        .first span, .second span {
               font-size: 8px;
              font-family:'OPPOL';
              transform:scale(0.88);
              margin-bottom: 8px;
              margin-right: 3px;
        }
        .first .item:nth-child(1), .second .item:nth-child(1) {
            transition-delay: 0.03s;
        }
        .first .item:nth-child(2), .second .item:nth-child(2) {
            transition-delay: 0.06s;
        }.first .item:nth-child(3), .second .item:nth-child(3) {
            transition-delay: 0.09s;
        }.first .item:nth-child(4), .second .item:nth-child(4) {
            transition-delay: 0.12s;
        }.first .item:nth-child(5), .second .item:nth-child(5) {
            transition-delay: 0.15s;
        }
        .first .item:nth-child(6), .second .item:nth-child(6) {
            transition-delay: 0.18s;
        }
        .wrap:hover .first .item {
            /* color: red; */
            transform: translateY(-110%);
        }
        .wrap:hover .second .item {
            /* color: green; */
            transform: translateY(0);
        }
</style>