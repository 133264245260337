<template>
  <div class="toright_box">
        <div class="hvr-sweep-to-right" @click="toclickTop">
            <div class="right_top">
                <p>科技资讯</p>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.72009 2.86011H15.1901" stroke="black" stroke-width="5" stroke-miterlimit="10" stroke-linecap="square"/>
                    <path d="M2.72009 2.86011L15.4601 15.6001" stroke="black" stroke-width="5" stroke-miterlimit="10"/>
                    <path d="M2.72009 2.86011V15.3301" stroke="black" stroke-width="5" stroke-miterlimit="10" stroke-linecap="square"/>
                 </svg>
           
            </div>
            <span>(COMMUNITY EVENTS)</span>
        </div>

        <div class="hvr-sweep-to-right" @click="founder_park">
            <div class="right_top">
                <p>创始人社区</p>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.72009 2.86011H15.1901" stroke="black" stroke-width="5" stroke-miterlimit="10" stroke-linecap="square"/>
                    <path d="M2.72009 2.86011L15.4601 15.6001" stroke="black" stroke-width="5" stroke-miterlimit="10"/>
                    <path d="M2.72009 2.86011V15.3301" stroke="black" stroke-width="5" stroke-miterlimit="10" stroke-linecap="square"/>
                 </svg>
           
                
            </div>
            <span>(COMMUNITY EVENTS)</span>
        </div>

        <div class="hvr-sweep-to-right" @click="founder_park_three">
            <div class="right_top">
                <p>社区活动</p>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.72009 2.86011H15.1901" stroke="black" stroke-width="5" stroke-miterlimit="10" stroke-linecap="square"/>
                    <path d="M2.72009 2.86011L15.4601 15.6001" stroke="black" stroke-width="5" stroke-miterlimit="10"/>
                    <path d="M2.72009 2.86011V15.3301" stroke="black" stroke-width="5" stroke-miterlimit="10" stroke-linecap="square"/>
                 </svg>
           
            </div>
            <span>(COMMUNITY EVENTS)</span>
        </div>
    </div>  
</template>

<script>
export default {
    data(){
        return{

        }
    },
    mounted(){

    },
    methods:{
        toclickTop(){
           document.querySelector('.geektech').scrollIntoView({
            behavior: 'smooth'
          })
          
        },
        founder_park(){
            document.querySelector('.founder_park').scrollIntoView({
            behavior: 'smooth'
          })
        },
        founder_park_three(){
            document.querySelector('.founder_park_two').scrollIntoView({
            behavior: 'smooth'
          })
        }
    }
}
</script>

<style lang="scss" scoped>

.toright_box{
            display: flex;
            width: 85%;
            justify-content:space-between;
            margin: 0 auto;
          }
.hvr-sweep-to-right {
    width: 100%;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  border-left: 1px solid #ccc;
  padding-top: 38px;
    padding-bottom: 62px;
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.7s;
  transition-duration: 0.6s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
  color: white;
}



.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

// .hvr-sweep-to-right{
//     width: fit-content
// }

.hvr-sweep-to-right>.right_top{
  display: flex;
    padding-left: 26px;
    // padding-right: 163px;
}

.hvr-sweep-to-right>.right_top>p{
    position: relative;
    font-size: 60px;
    width: fit-content;
    font-family:"OPPOB";
    line-height: 1;
    
}
// .hvr-sweep-to-right>.right_top svg{
//     display: block;
//     position: absolute;
//     top: 23%;
//     right: 139px;
// }
/* .hvr-sweep-to-right>.right_top img:nth-child(2){
   left: 276px 
} */
.hvr-sweep-to-right>span{
    font-size: 12px;
    display: block;
    padding-left: 26px;
}



.hvr-sweep-to-right:hover svg path{
    stroke: white;
    transition: color 0.4s linear 0s;
}
.hvr-sweep-to-right svg path{
    transition: color 0.4s linear 0s;
}

</style>