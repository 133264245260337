//main.js
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/element.js'; // 引入js文件
import './plugins/grap.js'; // 引入js文件
import "@/assets/text/text.css";

// import Vant from 'vant';
// import 'vant/lib/index.css';
import { Button} from 'vant';
import 'vue-loaders/dist/vue-loaders.css';  
	Vue.use(Button)
Vue.config.productionTip = false;
Vue.config.devtools = true
 
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');